import { useState } from "react";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [statusMessage, setStatusMessage] = useState(""); // För att visa status efter formulärinlämning

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);

    try {
      const response = await fetch("https://medinaredovisning.se/api/sendMail.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setStatusMessage("Meddelandet har skickats!");
        clearState();
      } else {
        setStatusMessage("Ett fel uppstod när meddelandet skulle skickas.");
      }
    } catch (error) {
      setStatusMessage("Ett nätverksfel uppstod. Försök igen senare.");
    }
  };

  const clearState = () => setState({ ...initialState });

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Kom i kontakt</h2>
                <p>
                  Vänligen fyll i formuläret nedan för att skicka oss ett mejl, så återkommer vi så snart som möjligt.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Namn"
                        required
                        value={name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="E-post"
                        required
                        value={email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Meddelande"
                    required
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Skicka meddelande
                </button>
                {statusMessage && <p>{statusMessage}</p>} {/* Visar statusmeddelande */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Medinaredovisning.</p>
        </div>
      </div>
    </div>
  );
};
