import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Våra tjänster</h2>
          <p>
            Vi erbjuder ett brett utbud av tjänster för att stödja ditt företags ekonomiska behov, från bokföring och skatterådgivning till finansiell analys och löneadministration. Med vårt team av experter ser vi till att ditt företag är i trygga händer.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "Laddar"}
        </div>
      </div>
    </div>
  );
};
